<template>
  <button
    :disabled="disabled"
    @click="$emit('buttonClicked')"
    :class="
      (solid
        ? 'btn-primary'
        : 'btn-outline btn-outline-primary btn-active-light-primary') +
      ' card-header-button-ai btn-sm btn me-2 mb-2'
    "
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "CardHeaderButtonAI",
  props: {
    solid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-header-button-ai {
  padding: 0.5rem 1.5rem !important;
  border-radius: 8px;
}
</style>
