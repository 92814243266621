<template>
  <div class="search-results">
    <div class="card mb-5 mb-xl-10">
      <div class="card-header border-0 pt-5 align-items-center">
        <div>
          <h2 class="heading">Results</h2>
        </div>
        <div>
          <el-checkbox
            style="margin-right: 1rem"
            :disabled="artistPage.length === 0"
            v-model="allSelected"
            @change="selectAllClicked"
            label="Select all on page"
            size="large"
          ></el-checkbox>
          <!--          <CardHeaderButtonAI> Crawl </CardHeaderButtonAI>-->
          <CardHeaderButtonAI> Save </CardHeaderButtonAI>
          <CardHeaderButtonAI
            @buttonClicked="runAI"
            :solid="true"
            :disabled="checkedArtistIds.length === 0"
          >
            Run AI
          </CardHeaderButtonAI>
        </div>
      </div>
      <div class="card-body">
        <el-table
          @sort-change="sortResults"
          row-key="id"
          :fit="true"
          table-layout="fixed"
          :data="artistPage"
        >
          <el-table-column label="" width="75">
            <template #default="scope">
              <div class="d-flex justify-content-center align-items-center">
                <el-avatar
                  @click="
                    $emit('viewArtistAnalytics', {
                      id: scope.row.id,
                      name: scope.row.name,
                    })
                  "
                  class="cursor-pointer my-2"
                  :size="35"
                  :src="scope.row.image"
                ></el-avatar>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            prop="name"
            label="Name"
            width="300"
          ></el-table-column>
          <el-table-column label="Followers" sortable="custom" prop="followers">
            <template #default="scope">
              <span>{{ Number(scope.row.followers).toLocaleString() }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="Popularity"
            sortable="custom"
            prop="popularity"
          >
          </el-table-column>
          <el-table-column label="Action">
            <template #default="scope">
              <ArtistMenuComponent
                :spotify-object="{
                  id: scope.row.id,
                  name: scope.row.name,
                }"
                :artist="scope.row"
                :from-drawer="false"
                :buttons="[
                  'artistDetails',
                  'discover',
                  'watchlist',
                  'crawl',
                  'crm',
                ]"
              />
            </template>
          </el-table-column>
          <el-table-column label="Select">
            <template #default="scope">
              <el-checkbox
                :model-value="checkedArtistIds.includes(scope.row.id)"
                :disabled="
                  !checkedArtistIds.includes(scope.row.id) &&
                  checkedArtistIds.length > 49
                "
                @change="checkBoxValueChanged($event, scope.row.id)"
                label=""
                size="large"
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          class="mt-5"
          v-model:currentPage="localPagination.offset"
          v-model:page-size="localPagination.limit"
          :page-sizes="[5, 10, 20, 50]"
          :small="true"
          layout="total, sizes, prev, pager, next"
          :total="localPagination.total"
          @size-change="paginationChanged"
          @current-change="paginationChanged"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import CardHeaderButtonAI from "../components/CardHeaderButtonAI";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { paginate, sortArray } from "../helpers";
import { getArtistsListenersBySpotifyIds } from "../../api/aiBetaBench";
import { ElNotification } from "element-plus";
import { useRouter } from "vue-router";
import ArtistMenuComponent from "../../artists/components/ArtistMenuComponent";
export default {
  name: "SearchResults",
  components: { ArtistMenuComponent, CardHeaderButtonAI },
  setup() {
    const store = useStore();
    const router = useRouter();
    const localPagination = ref({
      offset: 1,
      limit: 10,
      total: 0,
    });

    const checkedArtistIds = ref([]);
    const allSelected = ref(false);
    const sortBy = ref(null);
    const sortDir = ref(null);
    const artistPage = ref([]);

    const sortedAllArtists = ref([]);

    const pagination = computed(() => {
      return store.getters["AIBetaBench/getSearchedArtistsPagination"];
    });

    const searchedArtists = computed(
      () => store.getters["AIBetaBench/getSearchedArtists"]
    );

    const sortAllDataIfRequired = (array) => {
      if (sortBy.value && sortDir.value) {
        return sortArray(sortBy.value, sortDir.value, array);
      } else {
        return array;
      }
    };

    watch(
      () => pagination.value,
      (value) => {
        localPagination.value = value;
      },
      { immediate: true, deep: true }
    );

    watch(
      () => searchedArtists.value,
      (results) => {
        sortedAllArtists.value = sortAllDataIfRequired(results);
      },
      { immediate: true, deep: true }
    );

    watch(
      () => sortedAllArtists.value,
      (results) => {
        artistPage.value = [];
        artistPage.value = paginate(
          results,
          localPagination.value.limit,
          localPagination.value.offset
        );
      },
      { immediate: true, deep: true }
    );

    const checkBoxValueChanged = (value, id) => {
      allSelected.value = false;
      if (!value && checkedArtistIds.value.includes(id)) {
        const index = checkedArtistIds.value.indexOf(id);
        checkedArtistIds.value.splice(index, 1);
      }
      if (value && !checkedArtistIds.value.includes(id)) {
        checkedArtistIds.value.push(id);
      }
    };

    const selectAllClicked = (value) => {
      if (value) {
        checkedArtistIds.value = artistPage.value.map((object) => object.id);
      } else {
        checkedArtistIds.value = [];
      }
    };

    const sortResults = ({ prop, order }) => {
      sortBy.value = prop;
      sortDir.value = order;
      sortedAllArtists.value = sortAllDataIfRequired(
        JSON.parse(JSON.stringify(searchedArtists.value))
      );
    };

    const openInDiscovery = (artistName) => {
      // store.dispatch("EventsModule/searchArtist", artistName);
      store.dispatch("DiscoverModule/changeSelectedMenu", "Search");
      window.open(`/discover?search=${artistName}`, "_blank");
    };

    const paginationChanged = () => {
      allSelected.value = false;
      checkedArtistIds.value = [];
      store.commit(
        "AIBetaBench/SET_SEARCHED_ARTISTS_PAGINATION",
        localPagination.value
      );
      artistPage.value = paginate(
        sortedAllArtists.value,
        localPagination.value.limit,
        localPagination.value.offset
      );
      // store.dispatch("AIBetaBench/fetchArtistsByGenre");
    };

    const runAI = async () => {
      if (checkedArtistIds.value.length < 10) {
        ElNotification({
          title: "Error",
          message: "At least 10 artists required",
          type: "error",
        });
        return;
      }
      store.commit("AIBetaBench/SET_SEARCHED_RESULTS_LOADING", true);
      const payload = [];
      for (const valueElement of artistPage.value) {
        if (checkedArtistIds.value.includes(valueElement.id)) {
          payload.push({
            id: valueElement.id,
            name: valueElement.name,
          });
        }
      }
      try {
        const { data } = await getArtistsListenersBySpotifyIds(payload);
        store.commit("AIBetaBench/SET_AI_PARSED_DATA", data);
        store.commit("AIBetaBench/SET_SEARCHED_LISTENERS_PAGINATION", {
          offset: 1,
          limit: 10,
          total: data.artists.length,
        });
        router.push("/ai-beta-bench/listeners");
      } catch (e) {
        ElNotification({
          title: "Error",
          message: "Error getting data this time, try again later",
          type: "error",
        });
      }
      store.commit("AIBetaBench/SET_SEARCHED_RESULTS_LOADING", false);
    };

    return {
      searchedArtists,
      localPagination,
      artistPage,
      checkedArtistIds,
      allSelected,
      //Methods
      paginationChanged,
      openInDiscovery,
      sortResults,
      checkBoxValueChanged,
      selectAllClicked,
      runAI,
    };
  },
};
</script>

<style lang="scss">
.search-results {
  .result-btn {
    width: 8rem;
    border-radius: 10px;
  }
  .el-table {
    td {
      padding: 0 !important;
    }
  }
}
</style>
